import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabState } from "./activeTabTypes";

const initialState: TabState = {
  activeTab: null,
  activeTabInnerChildTab: null,
};

const activeTabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<string | null>) {
      state.activeTab = action.payload;
    },
    setActiveTabInnerChildTab(state, action: PayloadAction<string | null>) {
      state.activeTabInnerChildTab = action.payload;
    },
  },
});

export const { setActiveTab, setActiveTabInnerChildTab } =
  activeTabSlice.actions;
export default activeTabSlice.reducer;
