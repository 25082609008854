interface CUSTOM_BUTTON_PROP_TYPE {
  buttonText: string;
  customClassName: string;
  handleButtonClick: () => void;
  disabled?: boolean;
}

const CustomButton = ({
  buttonText,
  customClassName,
  handleButtonClick,
  disabled,
}: CUSTOM_BUTTON_PROP_TYPE) => {
  return (
    <button
      className={`btn ${customClassName}`}
      type="button"
      onClick={handleButtonClick}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
};

export default CustomButton;
