import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import ConfirmModal from "../modals/ConfirmModal";
import { deleteApi, putApi } from "../../apis/api";
import { useMessage } from "../../context/MessageContext";
import useOutsideClick from "../../hooks/useOutsideClick";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";

import {
  ActiveIcon,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
  DeleteIcon,
  UnactiveIcon,
} from "../../assets";
interface ActionConfigTypes {
  apiUrl: string;
  urlID: string;
  successMessage: string;
  heading?: string;
  para?: string;
}
interface TableRow {
  [key: string]: any;
  errors?: Record<string, string>; // Error field in each row
}
interface ValidationRule {
  key: keyof TableRow;
  errorMessage: string;
  type: string;
  secondKey?: string;
}
interface EditableTableProps {
  columns: ColumnTypes[];
  setLoading: (loading: boolean) => void;
  data: any[];
  setData: (data: any) => void;
  editType?: boolean;
  onDeleteConfig?: ActionConfigTypes;
  showDelete?: boolean;
  onActiveConfig?: ActionConfigTypes;
  isHandleActive?: boolean;
  getApiData?: (currentPage: number) => void;
  validationRules?: ValidationRule[];
  errors?: Record<string, string>;
  setErrors?: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  isDisabled?: boolean;
}
interface ConfirmTextTypes {
  heading: string;
  para: string;
}

interface ColumnTypes {
  key: string;
  header: string;
  isSortable: boolean;
  className?: string;
  isActive?: boolean;
  handleActive?: boolean;
  upperCase?: boolean;
  type?: string;
  dollar?: boolean;
  percent?: boolean;
  mandatory?: boolean;
}

const required = (value: string, message: string) => {
  if (!value) {
    return message;
  }
  return "";
};

const greaterThan = (
  value1: number,
  data: TableRow[],
  secondKey: string,
  message: string,
  rowIndex: number
) => {
  let value2 = parseFloat(data[rowIndex]?.[secondKey]);
  if (value1 <= value2) {
    return message;
  }
  return "";
};

const unique = (
  value: string,
  allValues: TableRow[],
  field: keyof TableRow,
  message: string,
  rowIndex: number
): string => {
  const isDuplicate = allValues.some(
    (item, index) => item[field] === value && index !== rowIndex
  );
  if (isDuplicate) {
    return message;
  }
  return "";
};

const latestGreater = (
  value: number,
  allValues: TableRow[],
  field: keyof TableRow,
  message: string,
  rowIndex: number
): string => {
  // Skip validation if rowIndex is 0 and count is 1
  if (rowIndex === 0 && allValues[0].count === 1) {
    return "";
  }

  let lastItem: TableRow | undefined;

  // Check if rowIndex is 0 and count is not 1
  if (rowIndex === 0 && allValues[0].count !== 1) {
    lastItem = allValues[allValues.length - 1]; // Get the last row
  } else {
    // Check if rowIndex is greater than 0
    lastItem = allValues[rowIndex - 1]; // Get the previous row
  }

  if (lastItem) {
    const lastEndWeight = parseFloat(lastItem[field]);
    if (value <= lastEndWeight) {
      return message;
    }
  }
  return "";
};

const zipCode = (value: string, message: string) => {
  if (!value || value.length < 5 || value.length > 9) {
    return message;
  }
  return "";
};

const EditableTable: React.FC<EditableTableProps> = ({
  columns,
  setLoading,
  data,
  setData,
  editType,
  onDeleteConfig,
  showDelete,
  onActiveConfig,
  isHandleActive,
  getApiData,
  validationRules,
  isDisabled,
}) => {
  const { setMessage } = useMessage();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const tableRef = useOutsideClick(() => setEditingRow(null));
  const [rowData, setRowData] = useState<any | null>(null);
  const [rowHandlerType, setRowHandlerType] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
  });
  const [confirmSuccessText, setConfirmSuccessText] = useState<string>("");
  const { currentPage } = useSelector((state: RootState) => state.pagination);

  // Sorting
  useEffect(() => {
    if (sortConfig !== null) {
      const sortedData = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setData(sortedData);
    }
  }, [sortConfig]);

  const requestSort = (key: string) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  // input change function
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    key: string
  ) => {
    const { value, type } = e.target;

    if (type === "number" && parseFloat(value) < 0) {
      return;
    }
    const newData = [...data];
    newData[rowIndex][key] = value;
    newData[rowIndex].isModified = true;

    const errorMessage = validateField(value, rowIndex, key);
    if (errorMessage) {
      if (!newData[rowIndex].errors) newData[rowIndex].errors = {};
      newData[rowIndex].errors[key] = errorMessage;
    } else {
      if (newData[rowIndex].errors) {
        delete newData[rowIndex].errors[key];
        if (Object.keys(newData[rowIndex].errors).length === 0) {
          delete newData[rowIndex].errors;
        }
      }
    }

    setData(newData);
  };

  // check input values on onchange and set error message
  const validateField = (
    value: any,
    rowIndex: number,
    key: keyof TableRow
  ): string => {
    if (validationRules) {
      const rulesForKey = validationRules.filter((rule) => rule.key === key);
      let errorMessage = "";

      for (const rule of rulesForKey) {
        switch (rule.type) {
          case "required":
            errorMessage = required(value, rule.errorMessage);
            break;
          case "greaterThan":
            errorMessage = greaterThan(
              parseFloat(value),
              data,
              rule?.secondKey || "",
              rule.errorMessage,
              rowIndex
            );
            break;
          case "unique":
            errorMessage = unique(
              value,
              data,
              key,
              rule.errorMessage,
              rowIndex
            );
            break;
          case "latestGreater":
            const startWeight = parseFloat(value);
            errorMessage = latestGreater(
              startWeight,
              data,
              rule?.secondKey || "",
              rule.errorMessage,
              rowIndex
            );
            break;
          case "zipCode":
            errorMessage = zipCode(value, rule.errorMessage);
            break;
          default:
            break;
        }

        if (errorMessage) {
          return errorMessage;
        }
      }
    }
    return "";
  };

  // Sort data by data.count when clicking outside the table
  useEffect(() => {
    if (editingRow === null) {
      const newData = [...data].sort((a, b) => a.count - b.count);
      setData(newData);
    }
  }, [editingRow]);

  // check table row click
  const handleRowClick = (rowIndex: number) => {
    if (isDisabled) {
      return;
    }
    setEditingRow(rowIndex === editingRow ? null : rowIndex);
  };

  // function to store action buttons
  const handleAction = (
    row: any,
    actionType: string,
    confirmMsgheading: string,
    confirmMsgheadPara: string,
    successMsg: string
  ) => {
    setRowData(row);
    setRowHandlerType(actionType);
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setConfirmSuccessText(successMsg);
    setConfirmModal(true);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation();
    if (row.id === "") {
      const newData = data.filter((_, index) => index !== rowIndex);
      newData.forEach((item, idx) => {
        item.count = idx + 1;
      });
      setData(newData);
    } else {
      handleAction(
        row,
        "Delete",
        onDeleteConfig?.heading || "Delete!",
        `delete ${onDeleteConfig?.para}`,
        onDeleteConfig?.successMessage || ""
      );
    }
  };

  const handleActiveUsers = (e: React.MouseEvent<HTMLDivElement>, row: any) => {
    e.stopPropagation();
    if (editType || isHandleActive || row?.id === "") {
      return;
    }
    const action = row.active ? "Deactivate" : "Activate";
    const actionSmallText = row.active ? "deactivated" : "activated";
    handleAction(
      row,
      "Active",
      `${action} ${onActiveConfig?.heading}`,
      `${actionSmallText} the ${onActiveConfig?.para}`,
      `${onActiveConfig?.successMessage} ${actionSmallText} successfully!`
    );
  };

  // function to hit api
  const handleConfirmAction = async () => {
    if (!rowData) return;
    setLoading(true);
    try {
      const configMap: { [key: string]: ActionConfigTypes | undefined } = {
        Delete: onDeleteConfig,
        Active: onActiveConfig,
      };
      const currentConfig = configMap[rowHandlerType];
      if (!currentConfig) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
        setLoading(false);
        return;
      }

      let apiUrl = `${currentConfig.apiUrl}${rowData[currentConfig.urlID]}`;

      const response =
        rowHandlerType === "Delete"
          ? await deleteApi(apiUrl)
          : await putApi(apiUrl, {
              isActive: !rowData.active ? "true" : "false",
            });

      if (response.success) {
        setConfirmModal(false);
        setMessage(confirmSuccessText, "success");
        getApiData && getApiData(currentPage);
      } else {
        setMessage(response.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="tableContainer" ref={tableRef}>
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  {column.isSortable ? (
                    <div
                      className="sortingDiv"
                      onClick={() =>
                        column.isSortable && requestSort(column.key)
                      }
                    >
                      {column.header}
                      {column?.mandatory && (
                        <span className="mandatory-mark">*</span>
                      )}
                      {column.isSortable && (
                        <span className="sortingDiv__icon">
                          {getSortIcon(column.key)}
                        </span>
                      )}
                    </div>
                  ) : column.isActive ? (
                    <div
                      className="sortingDiv sortingDiv--center"
                      onClick={() =>
                        column.isSortable && requestSort(column.key)
                      }
                    >
                      {column.header}
                      {column.isSortable && (
                        <span className="sortingDiv__icon">
                          {getSortIcon(column.key)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <>
                      {column.header}
                      {column?.mandatory && (
                        <span className="mandatory-mark">*</span>
                      )}
                    </>
                  )}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              data.map((row, rowIndex) => (
                <tr key={rowIndex} onClick={() => handleRowClick(rowIndex)}>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column.isActive ? (
                        row[column.key] ? (
                          <div
                            className="actions flex--center"
                            onClick={(e) => {
                              if (column.handleActive) {
                                handleActiveUsers(e, row);
                              }
                            }}
                          >
                            <button className="btn p_0 border_0">
                              <ActiveIcon />
                            </button>
                          </div>
                        ) : (
                          <div className="actions flex--center">
                            <div
                              className="btn p_0 border_0"
                              onClick={(e) => {
                                if (column.handleActive) {
                                  handleActiveUsers(e, row);
                                }
                              }}
                            >
                              <UnactiveIcon />
                            </div>
                          </div>
                        )
                      ) : (
                        <>
                          {editingRow !== rowIndex ? (
                            <div className="cellText">
                              <span className="cellText__dark">
                                {column.dollar
                                  ? `$${row[column.key]}`
                                  : column.percent
                                  ? `${row[column.key]}%`
                                  : row[column.key]}
                              </span>
                              {row.errors?.[column.key] && (
                                <span className="form__error">
                                  {row.errors[column.key]}
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="cellText">
                              <input
                                type={column.type || "text"}
                                className="form__input form__input--active"
                                value={row[column.key]}
                                onChange={(e) =>
                                  handleInputChange(e, rowIndex, column.key)
                                }
                                disabled={editingRow !== rowIndex}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={(e) => {
                                  if (
                                    (e.key === "e" ||
                                      e.key === "E" ||
                                      e.key === "-" ||
                                      e.key === "+") &&
                                    column.type === "number"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {row.errors?.[column.key] && (
                                <span className="form__error">
                                  {row.errors[column.key]}
                                </span>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  ))}
                  <td>
                    {!showDelete && (
                      <div className="actions flex--end">
                        <button
                          className="btn p_0 border_0"
                          onClick={(e) => handleDelete(e, rowIndex, row)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
        />
      )}
    </>
  );
};

export default EditableTable;
