import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AGENT_RATE_NAME } from "../../utils/authUtils";
import SwitchUserModal from "../modals/SwitchUserModal";
import { getTitleByRoute } from "../../utils/routeTitles";
import useOutsideClick from "../../hooks/useOutsideClick";
import { AppDispatch, RootState } from "../../redux/store";
import { logoutUser } from "../../redux/reducer/auth/authSlice";
import { setTitle } from "../../redux/reducer/navbar/navbarSlice";
import { firstLetterCapital, capitalizeEachWord } from "../../utils/utils";
import { getApi } from "../../apis/api";
import { GET_PARENT_CUSTOMER_DATA } from "../../apis/end-points";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";

import {
  EditProfileIcon,
  LogoutProfileIcon,
  SwtichIcon,
  BackTitleIcon,
} from "../../assets";
import { setActiveTabInnerChildTab } from "../../redux/reducer/activeTab/activeTabSlice";

export interface AddedByTenantsTypes {
  customerName: string;
  customerId: string;
  customerNumber: string;
  id: string;
}
interface GetCustomerApiResponse {
  addedByTenants: AddedByTenantsTypes[];
  currentCustomer: AddedByTenantsTypes;
}
interface RouteWithBackButton {
  path: string | RegExp;
  activeTabs: string[];
  shouldNavigateBack: boolean;
}

// Array of routes where back button should be shown
const routesWithBackButton: RouteWithBackButton[] = [
  {
    path: /^\/agent-list(\/[^/]+)?$/,
    activeTabs: ["Weight Zone", "Agent Rates"],
    shouldNavigateBack: false,
  },
  {
    path: "/customer/rate",
    activeTabs: ["Weight Zone", "Customer Rates"],
    shouldNavigateBack: false,
  },
  {
    path: "/email-notification-permissions",
    activeTabs: [],
    shouldNavigateBack: true,
  },
  {
    path: /^\/customer-order-history\/([^/]+)?$/,
    activeTabs: [],
    shouldNavigateBack: true,
  },
];

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [openSwitchUserModal, setOpenSwitchUserModal] =
    useState<boolean>(false);
  const [agentName, setAgentName] = useState<string>(
    localStorage.getItem(AGENT_RATE_NAME) ?? ""
  );
  const { title, username, userProfile, userRole, userEmail } = useSelector(
    (state: RootState) => state.navbar
  );
  const { activeTabInnerChildTab } = useSelector(
    (state: RootState) => state.activeTab
  );
  const [switchCustomerData, setSwitchCustomerData] = useState<
    AddedByTenantsTypes[]
  >([]);
  const [customerSelectedData, setCustomerSelectedData] =
    useState<AddedByTenantsTypes>({
      customerName: "",
      customerId: "",
      customerNumber: "",
      id: "",
    });

  /**
   * Checks if the current location matches any route that requires a back button
   * and if the activeTabInnerChildTab has required values.
   * @returns {boolean} True if a match is found, otherwise false.
   */
  const shouldShowBackButton = routesWithBackButton.some((route) => {
    const routeMatches =
      typeof route.path === "string"
        ? location.pathname.includes(route.path)
        : route.path.test(location.pathname);

    const activeTabMatches =
      !route.activeTabs.length ||
      (activeTabInnerChildTab &&
        route.activeTabs.includes(activeTabInnerChildTab));

    return routeMatches && activeTabMatches;
  });
  // console.log(activeTabInnerChildTab);

  /**
   * Toggles the visibility of the profile dropdown.
   */
  const changeProfileView = () => {
    setShowProfile(!showProfile);
  };

  /**
   * Handles user logout by dispatching the `logoutUser` action and navigating to the sign-in page.
   */
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/users/sign_in");
  };

  /**
   * Navigates to the user's profile edit page and closes the profile dropdown.
   */
  const handleUserEdit = () => {
    setShowProfile(false);
    navigate("/user/profile");
  };

  /**
   * Determines the behavior for back navigation based on the route's shouldNavigateBack value.
   */
  const handleNavigateBack = () => {
    const matchedRoute = routesWithBackButton.find((route) => {
      return typeof route.path === "string"
        ? location.pathname.includes(route.path)
        : route.path.test(location.pathname);
    });

    if (matchedRoute?.shouldNavigateBack) {
      navigate(-1);
    } else {
      dispatch(setActiveTabInnerChildTab(null));
    }
  };

  /**
   * Handles the opening of the Switch User modal.
   */
  const handleSwitchUser = () => {
    setOpenSwitchUserModal(true);
  };

  // Ref to handle clicks outside the dropdown to close it
  const dropdownRef = useOutsideClick(() => {
    setShowProfile(false);
  });

  /**
   * Fetches the parent customer data from the API.
   * Updates the state with the retrieved data.
   */
  const getParentCustomerData = async () => {
    try {
      const response = await getApi<GetCustomerApiResponse>(
        GET_PARENT_CUSTOMER_DATA
      );
      if (response.success) {
        const data = response.data?.addedByTenants ?? [];
        const mappedData: AddedByTenantsTypes[] = data?.map((item) => ({
          id: item?.customerId,
          customerId: item?.customerId,
          customerName: item?.customerName ?? "",
          customerNumber: item?.customerNumber ?? "",
        }));
        setSwitchCustomerData(mappedData);
        const currentCustomer =
          response.data?.currentCustomer ??
          ({} as Partial<AddedByTenantsTypes>);
        setCustomerSelectedData({
          customerName: currentCustomer?.customerName ?? "",
          customerId: currentCustomer?.customerId ?? "",
          customerNumber: currentCustomer?.customerNumber ?? "",
          id: currentCustomer?.customerId ?? "",
        });
      }
    } catch (error) {
      console.error(GENERAL_ERROR_MESSAGE, error);
    }
  };

  /**
   * Effect to update the page title based on the current route and agent name.
   *
   * @description This effect runs whenever the route changes, updating the navbar title
   * by dispatching the `setTitle` action with the title derived from the current path.
   */
  useEffect(() => {
    const currentTitle = getTitleByRoute(location.pathname, agentName);
    dispatch(setTitle(currentTitle));
  }, [location.pathname, dispatch, agentName]);

  /**
   * Effect to update the agent name from localStorage when it changes.
   */
  useEffect(() => {
    setAgentName(localStorage.getItem(AGENT_RATE_NAME) ?? "");
  }, [localStorage.getItem(AGENT_RATE_NAME)]);

  /**
   * Effect to fetch parent customer data when the component mounts.
   */
  useEffect(() => {
    getParentCustomerData();
  }, []);

  return (
    <>
      <div className="page__head">
        <h2 className="page__head--title">
          {(title.includes("Customer List -") || shouldShowBackButton) && (
            <button className="backBtn">
              <BackTitleIcon onClick={handleNavigateBack} />
            </button>
          )}
          {title}
        </h2>
        <div className="page__head__actions">
          <div className="userProfile">
            <button className="userProfile__link" onClick={changeProfileView}>
              <span className="picIcon">
                {/* <img className="picIcon__img" src="../images/user-profile-img.png" alt="" /> */}
                {userProfile}
              </span>
              {capitalizeEachWord(username ?? "")}
            </button>
            <div
              ref={dropdownRef}
              className={`userProfile__dropdown ${
                showProfile ? "userProfile__dropdown--open" : ""
              }`}
            >
              <div className="userdetials">
                <div className="userdetials__info">
                  <div className="pic">
                    {/* <img className="pic__img" src="../images/user-profile-img.png" alt="" />*/}
                    {userProfile}
                  </div>
                  <h3 className="name">{capitalizeEachWord(username ?? "")}</h3>
                  <h4 className="role">{firstLetterCapital(userRole ?? "")}</h4>
                  <p className="para">{userEmail}</p>
                </div>
                <div className="btn__group">
                  <div className="btn__group__item">
                    <button
                      type="button"
                      className="btn btn__primary"
                      onClick={handleUserEdit}
                    >
                      <EditProfileIcon />
                      Edit Profile
                    </button>
                  </div>
                  {!switchCustomerData.length && (
                    <div className="btn__group__item">
                      <button
                        type="button"
                        onClick={handleLogout}
                        className="btn btn__danger"
                      >
                        <LogoutProfileIcon />
                        Logout
                      </button>
                    </div>
                  )}
                  {switchCustomerData.length > 1 && (
                    <div className="btn__group__item">
                      <button
                        type="button"
                        className="btn btn__primary"
                        onClick={handleSwitchUser}
                      >
                        <SwtichIcon />
                        Switch User
                      </button>
                    </div>
                  )}
                  {switchCustomerData.length > 0 && (
                    <div className="btn__group__item">
                      <button
                        type="button"
                        onClick={handleLogout}
                        className="btn btn__danger btn__outlined"
                      >
                        <LogoutProfileIcon />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSwitchUserModal && (
        <SwitchUserModal
          handleClose={() => setOpenSwitchUserModal(false)}
          switchCustomerData={switchCustomerData}
          setSwitchCustomerData={setSwitchCustomerData}
          customerSelectedData={customerSelectedData}
          setCustomerSelectedData={setCustomerSelectedData}
        />
      )}
    </>
  );
};

export default Navbar;
