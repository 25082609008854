import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ConfirmModal from "../modals/ConfirmModal";
import { useMessage } from "../../context/MessageContext";
import {
  activePayloadApi,
  deleteApi,
  deletePayloadApi,
  postApi,
  putApi,
} from "../../apis/api";
import { RootState } from "../../redux/store";
import { AGENT_RATE_NAME } from "../../utils/authUtils";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import { setLocationData } from "../../redux/reducer/locationSlice/locationSlice";

import {
  ActiveIcon,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
  BlueViewIcon,
  DeleteIcon,
  EditUserIcon,
  GreenDownloadIcon,
  NavigateIcon,
  UnactiveIcon,
} from "../../assets";
interface ColumnTypes {
  key: string;
  header: string;
  isSortable: boolean;
  className?: string;
  isActive?: boolean;
  handleActive?: boolean;
  upperCase?: boolean;
}
interface ActionConfigTypes {
  apiUrl: string;
  urlID: string;
  tenantIdUrl?: string;
  successMessage?: string;
  heading?: string;
  para?: string;
  rowName?: string;
  secondRowName?: string;
  onboardedMessage?: string;
  deleteByPayload?: boolean;
  activeByPayload?: boolean;
  deleteByPayloadKeys?: string[];
  activeByPayloadKeys?: string;
  payloadKeys?: any;
  changeStateToView?: (hubStep: number, data: any) => void;
  notCheckedOnboarded?: boolean;
}

interface NavigateConfigTypes {
  apiUrl: string;
  urlID: string;
  stateData?: boolean;
  changeStateToEdit?: (hubStep: number, data: any) => void;
}

interface AddConfigTypes {
  heading: string;
  confirmationText: string;
  successMessage: string;
  apiUrl: string;
  urlID: string;
  payloadKeys: any;
  viewUrl: string;
  viewUrlID: string;
}

interface TablePropsTypes {
  columns: ColumnTypes[];
  data: any[];
  downlaodIcon?: boolean;
  viewIcon?: boolean;
  deleteIcon?: boolean;
  editIcon?: boolean;
  activeIcon?: boolean;
  addIcon?: boolean;
  setLoading: (loading: boolean) => void;
  onEditNaviagte?: NavigateConfigTypes;
  onDeleteConfig?: ActionConfigTypes;
  onActiveConfig?: ActionConfigTypes;
  onViewConfig?: ActionConfigTypes;
  addConfig?: AddConfigTypes;
  onCustomerNameNaviagte?: NavigateConfigTypes;
  getApiData?: (currentPage: number) => void;
  tenantId?: string;
  doSomethingOnParent?: (id: string) => void;
  profileLinkNaviagte?: NavigateConfigTypes;
  editModalHanlde?: (data: any) => void;
}

interface ConfirmTextTypes {
  heading: string;
  para: string;
}

const Table: React.FC<TablePropsTypes> = ({
  columns,
  data,
  downlaodIcon,
  viewIcon,
  deleteIcon,
  editIcon,
  activeIcon,
  addIcon,
  setLoading,
  onEditNaviagte,
  onDeleteConfig,
  onActiveConfig,
  onViewConfig,
  addConfig,
  onCustomerNameNaviagte,
  getApiData,
  tenantId,
  doSomethingOnParent,
  profileLinkNaviagte,
  editModalHanlde,
}) => {
  const { setMessage } = useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
  });
  const [confirmSuccessText, setConfirmSuccessText] = useState<string>("");
  const [rowData, setRowData] = useState<any | null>(null);
  const [rowHandlerType, setRowHandlerType] = useState<string>("");
  const { currentPage } = useSelector((state: RootState) => state.pagination);

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  // function to naviagte page
  const handleNavigatLink = (url: string, urlID: string) => {
    navigate(`${url}${urlID}`);
  };

  // function to naviagte page
  const handleNavigatLinkWithState = (
    url: string,
    urlID: string,
    data: any
  ) => {
    navigate(`${url}${urlID}`, { state: { data } });
    dispatch(setLocationData(data));
  };

  // function to store action buttons
  const handleAction = (
    row: any,
    actionType: string,
    confirmMsgheading: string,
    confirmMsgheadPara: string,
    successMsg: string
  ) => {
    setRowData(row);
    setRowHandlerType(actionType);
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setConfirmSuccessText(successMsg);
    setConfirmModal(true);
  };

  // function to set delete information
  const handleDelete = (row: any) => {
    handleAction(
      row,
      "Delete",
      onDeleteConfig?.heading || "Delete!",
      `delete ${onDeleteConfig?.rowName && row[onDeleteConfig?.rowName]} ${
        (onDeleteConfig?.secondRowName && row[onDeleteConfig?.secondRowName]) ||
        ""
      }`,
      onDeleteConfig?.successMessage || ""
    );
  };

  // function to set active information
  const handleActiveUsers = (row: any) => {
    if (!row?.isSignedUp && !onActiveConfig?.notCheckedOnboarded) {
      setMessage(
        `${onActiveConfig?.onboardedMessage || ""} is not onboarded yet!`,
        "error"
      );
      return;
    }
    const action = row.active ? "Deactivate" : "Activate";
    const paraAction = row.active ? "deactivate" : "activate";
    handleAction(
      row,
      "Active",
      `${action} ${onActiveConfig?.heading}`,
      `${paraAction} the ${
        onActiveConfig?.rowName && row[onActiveConfig?.rowName]
      } ${
        (onActiveConfig?.secondRowName && row[onActiveConfig?.secondRowName]) ||
        ""
      }`,
      `${onActiveConfig?.successMessage} ${
        row.active ? "deactivated" : "activated"
      } successfully!`
    );
  };

  // function to hit api
  const handleConfirmAction = async () => {
    if (!rowData) return;
    setLoading(true);
    try {
      const configMap: { [key: string]: ActionConfigTypes | undefined } = {
        Delete: onDeleteConfig,
        Active: onActiveConfig,
        Add: addConfig,
      };
      const currentConfig = configMap[rowHandlerType];
      if (!currentConfig) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
        setLoading(false);
        return;
      }

      let apiUrl = `${currentConfig.apiUrl}${rowData[currentConfig.urlID]}`;
      let payload: any = {};
      if (tenantId && rowData?.tenantId) {
        apiUrl = `${apiUrl}/${rowData?.tenantId}`;
      }
      if (currentConfig?.deleteByPayload) {
        apiUrl = `${currentConfig.apiUrl}`;
        currentConfig?.deleteByPayloadKeys &&
          currentConfig?.deleteByPayloadKeys.length &&
          currentConfig.deleteByPayloadKeys.map((item) => {
            payload[item] = rowData?.[item];
          });
      }
      if (currentConfig?.activeByPayload) {
        apiUrl = `${currentConfig.apiUrl}`;
        payload = {
          [currentConfig.activeByPayloadKeys || ""]:
            rowData?.[currentConfig.urlID],
          isActive: !rowData.active ? "true" : "false",
        };
      }
      if (currentConfig?.payloadKeys && currentConfig.payloadKeys?.length) {
        apiUrl = `${currentConfig.apiUrl}`;
        currentConfig.payloadKeys.map((item: string) => {
          payload[item] = rowData?.[item];
        });
      }

      const response =
        rowHandlerType === "Delete"
          ? currentConfig.deleteByPayload
            ? await deletePayloadApi(apiUrl, { data: payload })
            : await deleteApi(apiUrl)
          : rowHandlerType === "Add"
          ? await postApi(apiUrl, payload)
          : currentConfig.activeByPayload
          ? await activePayloadApi(apiUrl, payload)
          : await putApi(apiUrl, {
              isActive: !rowData.active ? "true" : "false",
            });

      if (response.success) {
        setConfirmModal(false);
        setMessage(confirmSuccessText, "success");
        getApiData && getApiData(currentPage);
      } else {
        setMessage(response.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddAction = (row: any) => {
    handleAction(
      row,
      "Add",
      addConfig?.heading ? addConfig.heading : "",
      addConfig?.confirmationText ? addConfig.confirmationText : "",
      addConfig?.successMessage ? addConfig.successMessage : ""
    );
  };

  return (
    <>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  {column.isSortable ? (
                    <div
                      className="sortingDiv"
                      onClick={() =>
                        column.isSortable && requestSort(column.key)
                      }
                    >
                      {column.header}
                      {column.isSortable && (
                        <span className="sortingDiv__icon">
                          {getSortIcon(column.key)}
                        </span>
                      )}
                    </div>
                  ) : column.isActive ? (
                    activeIcon && (
                      <div
                        className="sortingDiv sortingDiv--center"
                        onClick={() =>
                          column.isSortable && requestSort(column.key)
                        }
                      >
                        {column.header}
                        {column.isSortable && (
                          <span className="sortingDiv__icon">
                            {getSortIcon(column.key)}
                          </span>
                        )}
                      </div>
                    )
                  ) : (
                    column.header
                  )}
                </th>
              ))}
              {deleteIcon && editIcon && <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            {sortedData.length === 0 ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              sortedData.map((row, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column.isActive && activeIcon ? (
                        row[column.key] ? (
                          <div
                            className="actions flex--center"
                            onClick={() => {
                              if (column.handleActive) {
                                handleActiveUsers(row);
                              }
                            }}
                          >
                            <button className="btn p_0 border_0">
                              <ActiveIcon />
                            </button>
                          </div>
                        ) : (
                          <div className="actions flex--center">
                            <button
                              className="btn p_0 border_0"
                              onClick={() => {
                                if (column.handleActive) {
                                  handleActiveUsers(row);
                                }
                              }}
                            >
                              <UnactiveIcon />
                            </button>
                          </div>
                        )
                      ) : column?.className === "link" ? (
                        <div className="profile">
                          <span
                            className="profile__name profile__name--underline"
                            onClick={() => {
                              if (onCustomerNameNaviagte) {
                                localStorage.setItem(
                                  AGENT_RATE_NAME,
                                  row?.customerName
                                );
                                handleNavigatLink(
                                  onCustomerNameNaviagte?.apiUrl,
                                  row?.[onCustomerNameNaviagte?.urlID]
                                );
                              }
                            }}
                          >
                            {row[column.key]}
                            <NavigateIcon />
                          </span>
                        </div>
                      ) : column?.className === "profile" ? (
                        <div className="profile">
                          {row?.logo ? (
                            <div className="profile__initials uppercase">
                              <img src={row?.logo} alt={row[column.key]?.[0]} />
                            </div>
                          ) : (
                            <div className="profile__initials uppercase">
                              {row[column.key]?.[0]}
                            </div>
                          )}
                          <span className="profile__name">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "profileLink" ? (
                        <div
                          className="profile"
                          onClick={() => {
                            if (profileLinkNaviagte) {
                              localStorage.setItem(
                                AGENT_RATE_NAME,
                                row?.agentName
                              );
                              handleNavigatLinkWithState(
                                profileLinkNaviagte?.apiUrl,
                                row?.[profileLinkNaviagte?.urlID],
                                row
                              );
                            }
                          }}
                        >
                          <div className="profile__initials uppercase">
                            {row[column.key]?.[0]}
                          </div>
                          <span
                            className="profile__name"
                            style={{ textDecoration: "underline" }}
                          >
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "lastName" ? (
                        <div className="cellText">
                          <span className="cellText__primary">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "addNote" ? (
                        <span
                          className="profile__name profile__name--underline"
                          onClick={() =>
                            doSomethingOnParent && doSomethingOnParent(row?.id)
                          }
                        >
                          {`${
                            row[column.key] == "" ? "+Add Note" : "Edit Note"
                          }`}
                        </span>
                      ) : column?.upperCase ? (
                        <div className="cellText">
                          <span className="cellText__dark capitalize">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : (
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row[column.key]}
                          </span>
                        </div>
                      )}
                    </td>
                  ))}
                  {downlaodIcon ||
                  viewIcon ||
                  editIcon ||
                  deleteIcon ||
                  addIcon ? (
                    <td>
                      <div className="actions flex--end">
                        {downlaodIcon && (
                          <button className="btn p_0 border_0">
                            <GreenDownloadIcon />
                          </button>
                        )}
                        {viewIcon && (
                          <button
                            className="btn p_0 border_0"
                            onClick={() => {
                              if (onViewConfig) {
                                if (tenantId) {
                                  handleNavigatLink(
                                    onViewConfig?.apiUrl,
                                    `${row?.[onViewConfig?.urlID]}/${
                                      row?.tenantId
                                    }`
                                  );
                                } else if (onViewConfig?.changeStateToView) {
                                  onViewConfig?.changeStateToView(2, {
                                    [onViewConfig?.urlID]:
                                      row[onViewConfig?.urlID],
                                    [onViewConfig?.tenantIdUrl || ""]:
                                      row?.[onViewConfig?.tenantIdUrl || ""],
                                  });
                                } else {
                                  handleNavigatLink(
                                    onViewConfig?.apiUrl,
                                    row?.[onViewConfig?.urlID]
                                  );
                                }
                              }
                            }}
                          >
                            <BlueViewIcon />
                          </button>
                        )}
                        {editIcon && (
                          <button
                            disabled={row?.disableEditIcon}
                            className="btn p_0 border_0"
                            onClick={() => {
                              if (onEditNaviagte) {
                                if (tenantId) {
                                  handleNavigatLink(
                                    onEditNaviagte?.apiUrl,
                                    `${row?.[onEditNaviagte?.urlID]}/${
                                      row?.tenantId
                                    }`
                                  );
                                } else if (onEditNaviagte?.stateData) {
                                  handleNavigatLinkWithState(
                                    onEditNaviagte?.apiUrl,
                                    row?.[onEditNaviagte?.urlID],
                                    row
                                  );
                                } else if (onEditNaviagte?.changeStateToEdit) {
                                  onEditNaviagte?.changeStateToEdit(2, {
                                    [onEditNaviagte?.urlID]:
                                      row[onEditNaviagte?.urlID],
                                    serviceId: row?.serviceId || "",
                                  });
                                } else {
                                  handleNavigatLink(
                                    onEditNaviagte?.apiUrl,
                                    row?.[onEditNaviagte?.urlID]
                                  );
                                  localStorage.setItem(
                                    AGENT_RATE_NAME,
                                    row?.agentName
                                  );
                                }
                              } else if (editModalHanlde) {
                                editModalHanlde(row);
                              }
                            }}
                          >
                            <EditUserIcon />
                          </button>
                        )}
                        {deleteIcon && (
                          <button
                            className="btn p_0 border_0"
                            onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                        {addIcon &&
                          (row?.alreadyAdded ? (
                            <button
                              className="btn btn__primary"
                              onClick={() => {
                                addConfig &&
                                  // handleNavigatLink(
                                  //   addConfig && addConfig?.viewUrl,
                                  //   row?.[addConfig?.viewUrlID]
                                  // );
                                  handleNavigatLinkWithState(
                                    addConfig?.viewUrl,
                                    row?.[addConfig?.viewUrlID],
                                    row
                                  );
                              }}
                            >
                              View
                            </button>
                          ) : (
                            <button
                              className="btn btn__primary"
                              onClick={() => {
                                addConfig && handleAddAction(row);
                              }}
                            >
                              + Add
                            </button>
                          ))}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
        />
      )}
    </>
  );
};

export default Table;
