import React from "react";

import "../../assets/scss/abstracts/_modal.scss";

interface ConfirmModalProps {
  confirmInfo: {
    heading?: string;
    para?: string;
  };
  onConfirm: () => void;
  setConfirmModal: (value: boolean) => void;
  firstMessageShow?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  confirmInfo,
  onConfirm,
  setConfirmModal,
  firstMessageShow,
}) => {
  const onCancel = () => {
    setConfirmModal(false);
  };

  return (
    <>
      <div className="modal modalTrash modal--open">
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h4 className="modal__header--title">{confirmInfo?.heading}</h4>
              <p className="para">
                {firstMessageShow ? "" : "Are you sure you want to"}{" "}
                {confirmInfo?.para}?
              </p>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={onCancel}>
                  No
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default ConfirmModal;
