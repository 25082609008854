import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AppDispatch, RootState } from "../../redux/store";
import { setPage } from "../../redux/reducer/pagination/paginationSlice";

import {
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  SingleArrowLeftIcon,
  SingleArrowRightIcon,
} from "../../assets";

import "../../assets/scss/abstracts/_pagination.scss";

const Pagination: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { currentPage, totalPages, previousPage, nextPage, totalItems } =
    useSelector((state: RootState) => state.pagination);
  const [inputPage, setInputPage] = useState<number | "">(currentPage);

  /**
   * Handles page change events.
   *
   * Dispatches the page change action to the Redux store if the page is within valid bounds.
   *
   * @param page - The page number to navigate to.
   */
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      dispatch(setPage(page));
    }
  };

  /**
   * Calculates the page numbers to display in the pagination component.
   *
   * Limits the number of page numbers shown based on the current page
   * and the total number of pages.
   *
   * @returns An array of page numbers to display.
   */
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Maximum number of page buttons to display
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= halfMaxPagesToShow) {
        for (let i = 1; i <= maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        for (
          let i = currentPage - halfMaxPagesToShow;
          i <= currentPage + halfMaxPagesToShow;
          i++
        ) {
          pageNumbers.push(i);
        }
      }
    }

    return pageNumbers;
  };

  // Generate the array of page numbers to display based on the current page and total pages
  const pageNumbers = getPageNumbers();

  // Calculate the start and end indices for the current page's items
  const itemsPerPage = 15;
  const startIndex =
    totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  /**
   * Syncs the input field value with the current page whenever the current page changes.
   */
  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  return (
    <>
      <div className="pagination">
        <div className="pagination__result">
          {startIndex} - {endIndex} of {totalItems} results
        </div>
        <nav
          aria-label="pagination navigation"
          className="MuiPagination-root MuiPagination-outlined css-1oj2twp-MuiPagination-root"
        >
          <ul className="MuiPagination-ul css-wjh20t-MuiPagination-ul">
            <li>
              <button
                className="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-firstLast css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root"
                type="button"
                aria-label="Go to first page"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(1)}
              >
                <DoubleArrowLeftIcon />
              </button>
            </li>
            <li>
              <button
                className="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root"
                type="button"
                aria-label="Go to previous page"
                disabled={!previousPage}
                onClick={() => handlePageChange(previousPage ?? 1)}
              >
                <SingleArrowLeftIcon />
              </button>
            </li>
            {pageNumbers?.map((page, index) => (
              <li key={index}>
                <button
                  className={`MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded MuiPaginationItem-page css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root ${
                    currentPage === page ? "Mui-selected" : ""
                  }`}
                  type="button"
                  aria-current={currentPage === page ? "true" : undefined}
                  aria-label={`Go to page ${page}`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                  <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
                </button>
              </li>
            ))}
            {totalPages > 5 && currentPage + 2 < totalPages && (
              <li>
                <span className="pagination-ellipsis">...</span>
              </li>
            )}
            {totalPages > 5 && currentPage < totalPages - 2 && (
              <li>
                <button
                  className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded MuiPaginationItem-page css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root"
                  type="button"
                  aria-label={`Go to page ${totalPages}`}
                  onClick={() => handlePageChange(totalPages)}
                >
                  {totalPages}
                  <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
                </button>
              </li>
            )}
            <li>
              <button
                className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded MuiPaginationItem-previousNext css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root"
                type="button"
                aria-label="Go to next page"
                disabled={!nextPage}
                onClick={() => handlePageChange(nextPage ?? totalPages)}
              >
                <SingleArrowRightIcon />
                <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
              </button>
            </li>
            <li>
              <button
                className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-rounded MuiPaginationItem-firstLast css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root"
                type="button"
                aria-label="Go to last page"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(totalPages)}
              >
                <DoubleArrowRightIcon />
                <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination__actions">
          <h6>Go to page</h6>
          <input
            type="number"
            className="formField__input sm"
            // onChange={(e) => {
            //   const page = parseInt(e.target.value, 10);
            //   if (!isNaN(page)) handlePageChange(page);
            // }}
            value={inputPage}
            onChange={(e) => {
              const page = parseInt(e.target.value, 10);
              if (!isNaN(page) && page > 0 && page <= totalPages) {
                setInputPage(page);
              } else {
                setInputPage("");
              }
            }}
          />
          <button
            className="btn btn__primary"
            onClick={() => handlePageChange(inputPage as number)}
          >
            Go
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
