import { LOCAL_STORAGE_KEY } from "../../../utils/authUtils";

export interface LocationStateDataTypes {
  [key: string]: any; // handle varying keys
}

export const loadStateFromLocalStorage = () => {
  const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (serializedState) {
    return JSON.parse(serializedState);
  }
  return null;
};

export const saveStateToLocalStorage = (state: any) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
};
