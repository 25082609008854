import React, { useEffect, useState } from "react";

import useOutsideClick from "../../../hooks/useOutsideClick";
import ConfirmModal from "../../modals/ConfirmModal";
import { useMessage } from "../../../context/MessageContext";
import AddHazmatDetailsModal from "../../modals/AddHazmatDetailsModal";
import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";
import { deleteApi } from "../../../apis/api";

import { ArrowDownLightIcon, DeleteIcon, EditPenIcon } from "../../../assets";

interface ColumnChildren {
  header: string;
  key: string;
  type?: string;
}

interface Column {
  header: string;
  key: string;
  count?: boolean;
  sortable?: boolean;
  className: string;
  type?: string;
  children?: ColumnChildren[];
  mandatory?: boolean;
  editColumn?: boolean;
}

interface Data {
  [key: string]: any;
}

interface ActionConfigTypes {
  apiUrl: string;
  urlID: string;
  successMessage: string;
  heading?: string;
  para?: string;
}

interface ItemDetailsTableProps {
  columns: Column[];
  data: Data[];
  setData: (data: any) => void;
  setLoading: (loading: boolean) => void;
  onDeleteConfig?: ActionConfigTypes;
  isEditable?: boolean;
  showDelete?: boolean;
  setFields?: (field: any) => void;
  getApiCall?: () => void;
  setHasChanges?: (hasChange: boolean) => void;
}

interface ConfirmTextTypes {
  heading: string;
  para: string;
}

const ItemDetailsTable: React.FC<ItemDetailsTableProps> = (props) => {
  const {
    columns,
    data,
    setData,
    setLoading,
    onDeleteConfig,
    isEditable,
    showDelete,
    setFields,
    getApiCall,
    setHasChanges,
  } = props;
  const { setMessage } = useMessage();
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const tableRef = useOutsideClick(() => setEditingRow(null));
  const [rowData, setRowData] = useState<any | null>(null);
  const [rowHandlerType, setRowHandlerType] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
  });
  const [confirmSuccessText, setConfirmSuccessText] = useState<string>("");
  const [addHazmatDetailsModal, setAddHazmatDetailsModal] =
    useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number | null>(null);

  /**
   * Handles row click to enable editing mode if the table is editable.
   *
   * @param rowIndex - Index of the clicked row.
   */
  const handleRowClick = (rowIndex: number) => {
    if (!isEditable) {
      return;
    }
    setEditingRow(rowIndex === editingRow ? null : rowIndex);
  };

  /**
   * Shows the confirmation modal with action details.
   *
   * @param row - Data of the row to perform the action on.
   * @param actionType - Type of the action (e.g., Delete).
   * @param confirmMsgheading - Heading for the confirmation modal.
   * @param confirmMsgheadPara - Paragraph text for the confirmation modal.
   * @param successMsg - Success message to display after the action.
   */
  const handleAction = (
    row: any,
    actionType: string,
    confirmMsgheading: string,
    confirmMsgheadPara: string,
    successMsg: string
  ) => {
    setRowData(row);
    setRowHandlerType(actionType);
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setConfirmSuccessText(successMsg);
    setConfirmModal(true);
  };

  /**
   * Handles changes in input fields for table rows.
   *
   * @param e - The input change event.
   * @param rowIndex - Index of the row being edited.
   * @param key - Key of the column being edited.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    key: string
  ) => {
    const { value, type, checked } = e.target;
    const newData = [...data];

    if (type === "checkbox") {
      newData[rowIndex][key] = checked;
    } else if (type === "number" && parseFloat(value) < 0) {
      return;
    } else {
      newData[rowIndex][key] = value;
    }

    setHasChanges && setHasChanges(true);
    newData[rowIndex].isModified = true;
    setData(newData);
  };

  /**
   * Handles changes in child input fields for nested column data.
   *
   * @param e - The input change event.
   * @param rowIndex - Index of the row being edited.
   * @param parentKey - Key of the parent column.
   * @param childKey - Key of the child column.
   */
  const handleChildInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    parentKey: string,
    childKey: string
  ) => {
    const { value, type, checked } = e.target;
    const newData = [...data];

    if (type === "checkbox") {
      newData[rowIndex][parentKey][childKey] = checked;
    } else if (type === "number" && parseFloat(value) < 0) {
      return;
    } else {
      newData[rowIndex][parentKey][childKey] = value;
    }

    newData[rowIndex].isModified = true;
    setData(newData);
  };

  /**
   * Handles row deletion. Shows a confirmation modal for rows with an ID.
   *
   * @param e - The click event for the delete button.
   * @param rowIndex - Index of the row to delete.
   * @param row - Data of the row to perform the action on.
   */
  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation(); // Prevent triggering row click
    if (row.id === "") {
      const newData = data.filter((_, index) => index !== rowIndex);
      // Update count values
      newData.forEach((item, idx) => {
        item.count = idx + 1;
      });
      setData(newData);
    } else {
      handleAction(
        row,
        "Delete",
        onDeleteConfig?.heading || "Delete!",
        `delete ${onDeleteConfig?.para}`,
        onDeleteConfig?.successMessage || ""
      );
    }
  };

  /**
   * Opens the modal for editing hazmat details.
   *
   * @param e - The click event for the edit button.
   * @param rowIndex - Index of the row being edited.
   * @param row - Data of the row to perform the action on.
   */
  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation();
    setRowData(row);
    setRowIndex(rowIndex);
    setAddHazmatDetailsModal(true);
  };

  /**
   * Confirms and performs the delete action.
   */
  const handleConfirmAction = async () => {
    if (!rowData || !onDeleteConfig) return;
    setLoading(true);
    try {
      let apiUrl = `${onDeleteConfig.apiUrl}${rowData[onDeleteConfig.urlID]}`;

      const response = await deleteApi(apiUrl);
      if (response.success) {
        setConfirmModal(false);
        getApiCall?.();
        setHasChanges && setHasChanges(true);
        // close modal
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Calculates totals for handling units, weight, and pieces.
   *
   * @param data - The table data.
   * @returns An object containing the totals for handling units, weight, and pieces.
   */
  const calculateTotals = (data: Data[]) => {
    let totalHandlingUnits = 0;
    let totalWeight = 0;
    let totalPieces = 0;

    data?.forEach((item) => {
      const handlingUnits = parseFloat(item?.handlingUnit) || 0;
      const quantity = parseFloat(item?.quantity) || 0;
      const weight = parseFloat(item?.weight) || 0;
      const itemWeight = quantity * weight;

      totalHandlingUnits += handlingUnits;
      totalWeight += itemWeight;
      totalPieces += quantity;
    });

    return {
      handlingUnits: totalHandlingUnits,
      weight: totalWeight,
      pieces: totalPieces,
    };
  };

  /**
   * Calculates totals for handling units, weight, and pieces whenever `data` changes.
   * Updates the `setFields` state with the calculated totals.
   *
   * @effect Runs when `data` prop changes.
   */
  useEffect(() => {
    const totals = calculateTotals(data);
    setFields &&
      setFields((prev: any) => ({
        ...prev,
        destinationHandingUnits: totals.handlingUnits,
        destinationWeight: totals.weight,
        destinationPieces: totals.pieces,
      }));
  }, [data]);

  /**
   * Sorts the data array by `serial` property when no row is being edited.
   * Ensures the data is sorted correctly if `editingRow` is set to `null`.
   *
   * @effect Runs when `editingRow` state changes.
   */
  useEffect(() => {
    if (editingRow === null) {
      const sortedData = [...data].sort((a, b) => a.serial - b.serial);
      setData(sortedData);
    }
  }, [editingRow]);

  return (
    <>
      <div className="tableContainer" ref={tableRef}>
        <table className="table">
          <thead>
            <tr>
              {columns?.map((column) => (
                <th key={column.key}>
                  {column.className === "count" ? (
                    column.header
                  ) : column.className === "threeRow" ? (
                    <>
                      <div className="sortingDiv sortingDiv--center">
                        {column.header}
                      </div>
                      <div className="sortingDiv sortingDiv--Rowthree">
                        {column.children?.map((item) => (
                          <span className="item" key={item.key}>
                            {item.header}
                          </span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="sortingDiv">
                      {column.header}
                      {column?.mandatory && (
                        <span className="mandatory-mark">*</span>
                      )}
                      {column.sortable && (
                        <span className="sortingDiv__icon">
                          <ArrowDownLightIcon />
                        </span>
                      )}
                    </div>
                  )}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.length === 0 ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              data?.map((row, rowIndex) => (
                <tr key={rowIndex} onClick={() => handleRowClick(rowIndex)}>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column.className === "threeRow" ? (
                        <>
                          <div className="cellText">
                            <div className="sortingDiv sortingDiv--Rowthree">
                              {editingRow !== rowIndex
                                ? column?.children?.map((child) => (
                                    <span className="item" key={child?.key}>
                                      {row?.[column.key]?.[child.key]}
                                    </span>
                                  ))
                                : column?.children?.map((child) => (
                                    <span className="item" key={child.key}>
                                      <input
                                        className="form__input form__input--active"
                                        type={child.type || "text"}
                                        value={row[column.key][child.key]}
                                        onChange={(e) =>
                                          handleChildInputChange(
                                            e,
                                            rowIndex,
                                            column.key,
                                            child.key
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </span>
                                  ))}
                            </div>
                          </div>
                        </>
                      ) : column.className === "hazmat" ? (
                        <>
                          <div className="cellText">
                            <div className="form__checkbox form__checkbox--gray">
                              <div className="form__checkbox__option">
                                <input
                                  type="checkbox"
                                  id="hazmat"
                                  className="form__checkbox__input"
                                  checked={row[column.key]}
                                  onChange={(e) => {
                                    if (row[column.key]) {
                                      return;
                                    } else {
                                      setRowData(row);
                                      setRowIndex(rowIndex);
                                      setAddHazmatDetailsModal(true);
                                    }
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <label
                                  className="form__checkbox__label"
                                  htmlFor="hazmat"
                                >
                                  &nbsp;
                                </label>
                              </div>
                              <div className="form__checkbox__option">
                                <button
                                  className="btn p_0 border_0"
                                  onClick={(e) => handleEdit(e, rowIndex, row)}
                                >
                                  <EditPenIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {editingRow !== rowIndex || column.editColumn ? (
                            <div className="cellText">
                              <span className="cellText__dark">
                                {row[column.key]}
                              </span>
                            </div>
                          ) : (
                            <div className="cellText">
                              <input
                                type={column.type || "text"}
                                className="form__input form__input--active"
                                value={row[column.key]}
                                onChange={(e) =>
                                  handleInputChange(e, rowIndex, column.key)
                                }
                                disabled={editingRow !== rowIndex}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  ))}
                  <td>
                    {showDelete && (
                      <div className="actions flex--center">
                        <button
                          className="btn p_0 border_0"
                          onClick={(e) => handleDelete(e, rowIndex, row)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
        />
      )}
      {addHazmatDetailsModal && (
        <AddHazmatDetailsModal
          setConfirmModal={setAddHazmatDetailsModal}
          data={data}
          setData={setData}
          rowData={rowData}
          rowIndex={rowIndex}
        />
      )}
    </>
  );
};

export default ItemDetailsTable;
