import React, { useEffect, useState, useCallback, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useMessage } from "../../context/MessageContext";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import { getApi } from "../../apis/api";
import { XCrossIcon } from "../../assets";
import "../../assets/scss/abstracts/_form.scss";

export interface SingleDropdownItemType {
  id: string;
  name: string;
}

interface SingleDropdownProps {
  fieldName: string;
  selectedItem: SingleDropdownItemType | null;
  setSelectedData: (
    fieldName: string,
    item: SingleDropdownItemType | null
  ) => void;
  apiUrl?: string;
  staticData?: SingleDropdownItemType[];
  placeholder?: string;
  limit?: number;
  disable?: boolean;
  mandatory?: boolean;
  labelTitle?: string;
  extendedUrl?: Record<string, string>;
  keyBindName?: string;
  enableRemove?: boolean;
}

const SingleDropdown: React.FC<SingleDropdownProps> = ({
  fieldName,
  selectedItem,
  setSelectedData,
  apiUrl,
  staticData,
  placeholder = "-Select-",
  limit = 15,
  disable,
  mandatory,
  labelTitle,
  extendedUrl,
  keyBindName = "name",
  enableRemove,
}) => {
  const { setMessage } = useMessage();
  const [data, setData] = useState<SingleDropdownItemType[]>(staticData || []);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Detect outside clicks to close the dropdown
  const outsideClickRef = useOutsideClick(() => {
    setIsOpened(false);
    setIsSearchVisible(false);
  });

  const handleOpened = () => {
    if (!disable) {
      setIsOpened(!isOpened);
      setIsSearchVisible(false);
    }
  };

  const handleDoubleClick = () => {
    if (!disable) {
      setIsOpened(true);
      setIsSearchVisible(true);
    }
  };

  const handleRemoveSelection = () => {
    setSelectedData(fieldName, null);
    setIsOpened(false);
  };

  const fetchDropdownData = useCallback(
    async (reset: boolean = false) => {
      if (!apiUrl || isLoading) return;

      try {
        setIsLoading(true);
        let url = `${apiUrl}?limit=${limit}&page=${reset ? 1 : page}`;
        if (keyword) {
          url += `&keyword=${keyword}`;
        }

        const extendedUrlParams = Object.entries(extendedUrl || {})
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");

        if (extendedUrlParams) {
          url += `&${extendedUrlParams}`;
        }

        const response = await getApi(url);
        if (response.success) {
          const fetchedData: any = response.data || [];
          const mappedData = fetchedData.map((item: any) => ({
            id: item?.id,
            name: item?.[keyBindName],
          }));
          setData(reset ? mappedData : [...data, ...mappedData]);
          setHasMore(mappedData.length >= limit);
        } else {
          setMessage(response.error?.message || GENERAL_ERROR_MESSAGE, "error");
        }
      } catch (error) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
      } finally {
        setIsLoading(false);
      }
    },
    [apiUrl, keyword, page, limit, extendedUrl, data, isLoading, setMessage]
  );

  useEffect(() => {
    if (keyword) {
      const debounceFetch = setTimeout(() => {
        fetchDropdownData(true);
      }, 1000);
      return () => clearTimeout(debounceFetch);
    } else if (apiUrl) {
      fetchDropdownData(true);
    }
  }, [apiUrl, keyword]);

  useEffect(() => {
    if (page > 1) {
      fetchDropdownData();
    }
  }, [page]);

  const handleScroll = () => {
    if (dropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore) {
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpened) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (isSelected === null || isSelected === data.length - 1) {
          setIsSelected(0);
        } else {
          setIsSelected((prev) => (prev! + 1) % data.length);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (isSelected === null || isSelected === 0) {
          setIsSelected(data.length - 1);
        } else {
          setIsSelected((prev) => (prev! - 1 + data.length) % data.length);
        }
        break;
      case "Enter":
        if (isSelected !== null) {
          setSelectedData(fieldName, data[isSelected]);
          setIsOpened(false);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isSelected !== null && isOpened) {
      const selectedItem = document.querySelector(".selected");
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [isSelected, isOpened]);

  return (
    <div className="form__Field" ref={outsideClickRef}>
      {labelTitle && (
        <label className="form__label">
          {labelTitle} {mandatory && <span className="mandatory-mark">*</span>}
        </label>
      )}
      <div
        className={`customSelect ${isOpened ? "open" : ""}`}
        ref={outsideClickRef}
        onKeyDown={handleKeyDown}
      >
        {isSearchVisible ? (
          <input
            type="text"
            placeholder="Search..."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="form__input form__input--search form__input--sm"
            autoFocus
          />
        ) : (
          <div
            className={`customSelect__caption ${
              disable ? "customSelect__caption--disabled" : ""
            }`}
            onClick={handleOpened}
            onDoubleClick={handleDoubleClick}
          >
            {selectedItem ? (
              selectedItem.name
            ) : (
              <span className="customSelect__caption__placeholder">
                {placeholder}
              </span>
            )}

            {selectedItem && enableRemove && !disable && (
              <span
                style={{
                  background: "#fff",
                  position: "absolute",
                  right: "8px",
                  top: "10px",
                }}
                onClick={() => {
                  handleRemoveSelection();
                }}
              >
                <XCrossIcon />
              </span>
            )}
          </div>
        )}
        <div className="customSelect__dropdown" ref={dropdownRef}>
          <div className="customSelect__dropdown__body">
            {isLoading && page === 1 ? (
              <div className="customSelect__dropdown__noitem">
                <div className="col">Loading...</div>
              </div>
            ) : data?.length === 0 ? (
              <div className="customSelect__dropdown__noitem">
                <div className="col">No data found.</div>
              </div>
            ) : (
              data?.map((item, index) => (
                <div
                  className={`customSelect__dropdown__item ${
                    selectedItem?.id === item.id ? "selected" : ""
                  } ${isSelected === index ? "highlighted" : ""}`}
                  onClick={() => {
                    setSelectedData(fieldName, item);
                    setIsOpened(false);
                  }}
                  key={item.id}
                >
                  {item.name}
                </div>
              ))
            )}
            {isLoading && page > 1 && (
              <div className="customSelect__dropdown__noitem">
                <div className="col">Loading more...</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDropdown;
