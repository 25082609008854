import { lazy } from "react";

const Home = lazy(() => import("./home/Home"));
const Login = lazy(() => import("./login/Login"));
const ForgotPassword = lazy(() => import("./forgot-password"));
const UserList = lazy(() => import("./users/UserList"));
const AddUser = lazy(() => import("./users/AddUser"));
const ChangePassword = lazy(() => import("./change-password/ChangePassword"));
const Prior = lazy(() => import("./prior/Prior"));

// Profile Pages
const Profile = lazy(() => import("./profile/Profile"));
const EmailNotificationPermissions = lazy(
  () => import("./profile/EmailNotificationPermissions")
);

// Customer Management
const Customers = lazy(() => import("./customer-management/Customers/index"));
const CustomerMarkupList = lazy(() => import("./customer-management/CustomerMarkup/index"));
const AddEditCustomerMarkup = lazy(() => import("./customer-management/CustomerMarkup/CustomerMarkup"));
const AddCustomer = lazy(
  () => import("./customer-management/Customers/AddCustomer")
);
const CustomerUsers = lazy(() => import("./customer-management/CustomerUsers"));
const AddEditCustomerUsers = lazy(
  () => import("./customer-management/CustomerUsers/AddEditCustomerUsers")
);
const AddCustomerOfCustomer = lazy(
  () => import("./customer-management/Customers/Customers")
);
const CustomerRate = lazy(() => import("./customer-management/CustomerRates"));

// Agent Management
const AgentList = lazy(() => import("./agent-management/AgentList/index"));
const AddEditAgentList = lazy(
  () => import("./agent-management/AgentList/AddEditAgentList")
);
const AgentListTabs = lazy(
  () => import("./agent-management/AgentList/AgentListTabs")
);
const MasterAgentList = lazy(
  () => import("./agent-management/MasterAgentList")
);
const MasterAgentListTabs = lazy(
  () => import("./agent-management/MasterAgentList/MasterAgentListTabs")
);
const AgentAdditionalCharges = lazy(
  () => import("./agent-management/AgentAdditionalCharges/index")
);
const AgentHubs = lazy(() => import("./agent-management/AgentHubs/index"));
const AddEditAgentHub = lazy(
  () => import("./agent-management/AgentHubs/addEditAgentHub/index")
);
const AgentDocList = lazy(
  () => import("./agent-management/AgentDocList/index")
);

// Order Mangement
const CustomerOrder = lazy(
  () => import("./Order-management/CustomerOrder/index")
);
const CustomerOrderHistory = lazy(
  () => import("./Order-management/CustomerOrderHistory/index")
);
const CustomerOrderHistoryEdit = lazy(
  () =>
    import("./Order-management/CustomerOrderHistory/CustomerOrderHistoryEdit")
);
const DispatchBoardList = lazy(
  () => import("./Order-management/DispatchBoard/index")
);
const BilledOrderHistory = lazy(
  () => import("./Order-management/BilledOrderHistory/index")
);
const ViewBilledOrderHistory = lazy(
  () => import("./Order-management/BilledOrderHistory/ViewBilledOrderHistory")
);

// Api Management
const XcelaratorApi = lazy(
  () => import("./api-management/XcelaratorApi/index")
);
const AddEditXcelaratorApi = lazy(
  () => import("./api-management/XcelaratorApi/AddEditXcelaratorApi")
);
const AddEditXcelaratorApiTabs = lazy(
  () => import("./api-management/XcelaratorTabs/index")
);

export {
  CustomerMarkupList,
  AddEditCustomerMarkup,
  Home,
  Login,
  ForgotPassword,
  Profile,
  AddUser,
  ChangePassword,
  UserList,
  Prior,
  Customers,
  AddCustomer,
  CustomerUsers,
  AddEditCustomerUsers,
  AgentList,
  AddEditAgentList,
  AgentListTabs,
  MasterAgentList,
  MasterAgentListTabs,
  AgentAdditionalCharges,
  AgentHubs,
  AddEditAgentHub,
  AgentDocList,
  CustomerOrder,
  CustomerOrderHistory,
  CustomerOrderHistoryEdit,
  DispatchBoardList,
  BilledOrderHistory,
  ViewBilledOrderHistory,
  XcelaratorApi,
  AddEditXcelaratorApi,
  AddEditXcelaratorApiTabs,
  AddCustomerOfCustomer,
  CustomerRate,
  EmailNotificationPermissions,
};
