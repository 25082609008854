import { useEffect, useRef } from "react";

/**
 * Custom hook that triggers a callback function when a click is detected outside of the referenced element.
 *
 * @param {() => void} callback - The function to be called when an outside click is detected.
 * @returns - A React ref object to be attached to the element to monitor.
 */
const useOutsideClick = (callback: () => void) => {
  // Create a reference to the DOM element you want to monitor.
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * Handles the click event and checks if it occurred outside the referenced element.
     *
     * @param {MouseEvent} event - The mouse event triggered by the click.
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    // Add event listener to detect clicks outside the element.
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount or when dependencies change.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return ref;
};

export default useOutsideClick;
